import firebase from 'firebase/app'

const createInvoice = async (
  userId: string,
  orderId: string
): Promise<void> => {
  const createInvoiceFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('createInvoice')
  await createInvoiceFunction({
    user_id: userId,
    order_id: orderId
  })
}

export { createInvoice }
