





































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { User } from '@/models/User'
import { UserOrder } from '@/models/UserOrder'
import Button from '@/components/Button.vue'
import OrderNumber from '@/components/orders/OrderNumber.vue'
import { getDownloadUrl } from '@/services/Storage'
import { mainStore } from '@/store'
import { createInvoice } from '@/services/InvoiceService'

@Component({
  components: {
    OrderNumber,
    Button
  }
})
export default class OrderSummary extends Vue {
  @Prop()
  order: UserOrder

  @Prop()
  user: User

  downloading: boolean = false

  async downloadInvoice() {
    this.downloading = true

    if (!this.order.invoiceNumber) {
      await createInvoice((this.user ?? mainStore.user).id, this.order.id)
    }

    const downloadUrl = await getDownloadUrl(
      `/users/${(this.user ?? mainStore.user).id}/orders/${this.order.id}/${this.order.invoiceNumber
      }.pdf`
    )
    this.download(downloadUrl, `${this.order.invoiceNumber}.pdf`)
    this.downloading = false
  }

  download(url: string, filename: string) {
    fetch(url).then((t) => {
      return t.blob().then((b) => {
        const a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', filename)
        a.click()
      })
    })
  }
}
