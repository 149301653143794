import firebase from 'firebase/app'
import { UserOrderItem } from '@/models/UserOrderItem'

const createOrder = async ({
  items
}: {
  items: UserOrderItem[]
}): Promise<{ orderId: string }> => {
  const createOrderFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('createOrder')
  const result = await createOrderFunction({
    items: items.map((item) => {
      return {
        product_id: item.product.id,
        params: item.params
      }
    })
  })

  return {
    orderId: result.data.order_id
  }
}

const fulfillOrder = async (userId: string, orderId: string): Promise<void> => {
  const fulfillOrderFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('fulfillOrder')
  await fulfillOrderFunction({
    user_id: userId,
    order_id: orderId
  })
}

export { createOrder, fulfillOrder }
