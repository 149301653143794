

























import { Component, Vue } from 'vue-property-decorator'
import Content from '@/components/Content.vue'
import Button from '@/components/Button.vue'
import Loading from '@/components/Loading.vue'
import Tile from '@/components/Tile.vue'
import OrderSummary from '@/components/orders/OrderSummary.vue'
import OrderItemList from '@/components/orders/OrderItemList.vue'
import { firestore } from 'firebase/app'
import { UserOrderConverter } from '@/models/converters/UserOrderConverter'
import { UserOrder } from '@/models/UserOrder'
import { User } from '@/models/User'
import { UserConverter } from '@/models/converters/UserConverter'
import { UserOrderItem } from '@/models/UserOrderItem'
import { UserOrderItemConverter } from '@/models/converters/UserOrderItemConverter'
import { fulfillOrder } from '@/services/OrderService'

@Component({
  components: {
    Content,
    Button,
    Loading,
    Tile,
    OrderSummary,
    OrderItemList
  }
})
export default class AdminOrderDetail extends Vue {
  loading: boolean = true
  fulfilling: boolean = false
  user: User = null
  order: UserOrder = null
  orderItems: UserOrderItem[] = []
  users: { [id: string]: User } = {}

  async created() {
    const userRef = firestore()
      .collection('users')
      .doc(this.$route.params.userId)
      .withConverter(new UserConverter())
    const orderRef = userRef
      .collection('orders')
      .doc(this.$route.params.orderId)
      .withConverter(new UserOrderConverter())

    this.user = (await userRef.get()).data()
    orderRef.onSnapshot(async (snapshot) => {
      this.order = snapshot.data()
    })

    this.orderItems = (
      await orderRef
        .collection('items')
        .withConverter(new UserOrderItemConverter())
        .get()
    ).docs.map((doc) => doc.data())

    for (const orderItem of this.orderItems) {
      await orderItem.populate()
    }

    this.loading = false
  }

  async fulfill() {
    this.fulfilling = true
    await fulfillOrder(this.user.id, this.order.id)
    this.fulfilling = false
  }
}
